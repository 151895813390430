
export const GET_IMPORT_SUPSCRIPTION_REMINING_COUNT = 'GET_IMPORT_SUPSCRIPTION_REMINING_COUNT'

export const GET_IMPORT_SUPSCRIPTION_REMINING_COUNT_RESPONSE = 'GET_IMPORT_SUPSCRIPTION_REMINING_COUNT_RESPONSE'

export const ADD_IMPORT_SUBSCRIPTIONS = 'ADD_IMPORT_SUBSCRIPTIONS'

export const ADD_IMPORT_SUBSCRIPTIONS_RESPONSE = 'ADD_IMPORT_SUBSCRIPTIONS_RESPONSE'

export const ADD_IMPORT_SUBSCRIPTIONS_RESPONSE_CLEAR = 'ADD_IMPORT_SUBSCRIPTIONS_RESPONSE_CLEAR'

export const ADD_IMPORT_SUBSCRIPTIONS_ERROR = 'ADD_IMPORT_SUBSCRIPTIONS_ERROR'

export const ADD_IMPORT_SUBSCRIPTIONS_ERROR_CLEAR = 'ADD_IMPORT_SUBSCRIPTIONS_ERROR_CLEAR'

export const CLEAR_IMPORT_SUPSCRIPTION_REMINING_COUNT = 'CLEAR_IMPORT_SUPSCRIPTION_REMINING_COUNT'
