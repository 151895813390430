import React, { Component, createContext } from 'react'
import { AuthContext } from './auth/AuthContext'

const GlobalContext = createContext({
    user: {},
    currentRouterLocation: '',
    setValue: () => {},
})

class GlobalContextProvider extends Component {
    constructor (props) {
        super(props)
        this.setValue = this.setValue.bind(this)
    }

    setValue = (object) => {

        for (var key in object) {
            console.log('Setting Shared value', `${key}: ${object[key]}`)
            this.setState({
                [key]: object[key],
            })
        }

    }

    state = {
        user: {},
        currentRouterLocation: '',
        setValue: () => {},
    }

    render () {
        return (
            <GlobalContext.Provider
                value={{
                    user: this.state.user,
                    setValue: this.setValue,
                    currentRouterLocation: this.state.currentRouterLocation,
                }}>
                {this.props.children}
            </GlobalContext.Provider>
        )
    }

}

const GlobalContextConsumer = GlobalContext.Consumer

export { GlobalContextConsumer, GlobalContextProvider, GlobalContext }
