var errors = {
    402: "unauthorized, please login again",
    401: "unauthorized, please login again",
    404: "User Not Found",
    500: "Invalid input",
    400: "Server Error",
    403: "User Exist",
    429: "Limit Exceeded ",
    422: "Invalid Input,Please Retry. "
};

export async function signIn(email, password) {
    let response;
    try {
        response = await fetch("/v1/login", {
            method: "post",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: email,
                password: password
            })
        });
    } catch (e) { }

    if (response.status === 200) {
        return await response.json();
    } else {
        let message = await response.json();
        throw new Error(message.error);
    }
}

export async function register(email,name,businessName,planName,period) {
    let response;
    try {
        response = await fetch("/v1/landenWebhook?code="+process.env.REACT_APP_LANDEN_CODE, {
            method: "post",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                Email: email,
                name: name,
                businessName:businessName,
                planName:planName,
                period:period
            })
        });
    } catch (e) { }

    if (response.status === 200) {
        return await response.json();
    } else {
        let message = await response.json();
        throw new Error(message.error);
    }
}
export async function updateProfileDetails(
    firstname,
    lastname,
    country,
    ccode
) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/profiledetails", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify({ firstname, lastname, country, ccode })
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status == 422) {
        throw new Error("You are already signed up. Please login to continue.");
    } else if (response.status == 400) {
        throw new Error(
            "token is expired or token is unauthorized, please login again"
        );
    }
}

export async function updatePreferenceDetails(currency, country) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/preferencedetails", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify({ currency, country })
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status === 400) {
        throw new Error(
            "token is expired or token is unauthorized, please login again"
        );
    }
}

export async function getCurrencyNames() {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/getcurrencynames", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status === 400) {
        throw new Error(
            "token is expired or token is unauthorized, please login again"
        );
    }
}

export async function googleSignIn(data, LoginType) {
    const response = await fetch("/v1/googlelogin", {
        method: "post",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            data: data,
            LoginType: LoginType
        })
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        throw new Error(errors[response.status]);
    }
}

export async function signUp(name, email, password) {
    const response = await fetch("/v1/register", {
        method: "post",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            name: { first: name },
            email: email,
            password: password
        })
    });
    if (response.status == 201) {
        return await response.json();
    } else {
        let errors = await response.json();
        throw new Error(errors.error);
    }
}

export async function verify(code) {
    const response = await fetch("/v1/verify/email/" + code, {
        method: "post",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            code: code
        })
    });
    if (response.status == 200) {
        return await response.json();
    } else {
        throw new Error(errors[response.status]);
    }
}

export async function addNewEmail(body) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/addemail", {
        method: "post",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(body)
    });
    if (response.status == 200) {
        return await response.json();
    } else {
        const result = await response.json();
        throw new Error(result.error);
    }
}

export async function VerifyAddNewEmail(id, code) {
    const response = await fetch(`/v1/verifyaddemail/${id}/${code}`, {
        method: "post",
        headers: {
            "Content-Type": "application/json"
        }
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        const result = await response.json();
        throw new Error(result.error);
    }
}

export async function DeleteEmailId(email) {
    const token = localStorage.getItem("token");
    const response = await fetch(`/v1/deleteemail/${email}`, {
        method: "delete",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    if (response.status == 200) {
        return await response.json();
    } else {
        const result = await response.json();
        throw new Error(result.error);
    }
}

export async function getEmailDatas() {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/emailRecords", {
        method: "get",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    if (response.status == 200) {
        return await response.json();
    } else if (response.status == 500 || response.status == 401) {
    } else {
        let errorResponse = await response.json();
        throw new Error(errorResponse.error);
    }
}

export async function getUser() {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/user", {
        method: "get",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    if (response.status == 200) {
        return await response.json();
    } else if (response.status === 401) {
        throw new Error(
            "token is expired or token is unauthorized, please login again"
        );
    } else {
        let message = await response.json();
        throw new Error(message.error);
    }
}
export async function getUserDetail() {
    const token = localStorage.getItem("token");
    let response;
    try {
        response = await fetch("/v1/getUser", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token
            }
        });
    } catch (e) { }

    if (response.status === 200) {
        return await response.json();
    } else {
        let message = await response.json();
        throw new Error(message.error);
    }
}

export async function getUserAdmin() {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/checkadmin", {
        method: "get",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    if (response.status == 200) {
        return await response.json();
    } else {
        let errorResponse = await response.json();
        throw new Error(errorResponse.error);
    }
}

export async function getUserDetails(body) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/fetchuserdatas", {
        method: "post",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(body)
    });
    if (response.status == 200) {
        return await response.json();
    } else {
        return await response.json();
    }
}

export async function updateUser(body) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/user", {
        method: "put",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(body)
    });
    if (response.status == 200) {
        return await response.json();
    } else if (response.status === 401) {
        throw new Error("User Unauthorized. Please login again.");
    } else {
        let errorResponse = await response.json();
        throw new Error(errorResponse.error);
    }
}

//RequestForgetPassword
export async function requestForgetPassword(email) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/resetpassword", {
        method: "post",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify({
            email: email
        })
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status === 401) {
        throw new Error("User Unauthorized. Please login again.");
    } else {
        let errorResponse = await response.json();
        throw new Error(errorResponse.error);
    }
}

export async function resetPassword(code, newPassword) {
    const response = await fetch("/v1/resetpassword/verify/" + code, {
        method: "post",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            code: code,
            newPassword: newPassword
        })
    });
    if (response.status == 200) {
        return await response.json();
    } else {
        let errorResponse = await response.json();
        throw new Error(errorResponse.error);
    }
}


export async function resetpasswordmanually(currentPassword, newpassword) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/resetPasswords", {
        method: "post",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify({
            currentPassword: currentPassword,
            newpassword: newpassword
        })
    });
    if (response.status == 200) {
        return await response.json();
    } else {
        let errorResponse = await response.json();
        throw new Error(errorResponse.error);
    }
}



//Tag

export async function addTagDetails(tagname, tagcolor) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/addtagdetails", {
        method: "post",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify({
            tagName: tagname,
            tagColor: tagcolor
        })
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        let errorResponse = await response.json();
        throw new Error(errorResponse.error);
    }
}

export async function updateTagDetails(tagname, tagcolor, id) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/updatetagdetails/" + id, {
        method: "put",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify({
            tagName: tagname,
            tagColor: tagcolor
        })
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status == 404) {
        throw new Error("Tag not Found");
    } else {
        return response;
    }
}

export async function deleteTagDetails(id) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/deletetagdetails/" + id, {
        method: "delete",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status == 400) {
        throw new Error(
            "token is expired or token is unauthorized, please login again"
        );
    }
}

export async function getTag(id) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/gettagdetails/" + id, {
        method: "get",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status == 400) {
        throw new Error("unauthorized, please login again");
    }
}

export async function getAllTags() {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/gettags", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status === 401) {
        throw new Error("User Unauthorized. Please login again.");
    } else {
        let errorResponse = await response.json();
        throw new Error(errorResponse.error);
    }
}

//Product

export async function addProduct(body) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/product", {
        method: "post",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(body)
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status === 401) {
        throw new Error("User Unauthorized. Please login again.");
    } else {
        let errorResponse = await response.json();
        throw new Error(errorResponse.error);
    }
}

export async function getProductLogo(ProductName) {
    const token = localStorage.getItem("token");

    const response = await fetch("/v1/getproductslogo", {
        method: "post",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify({
            productname: ProductName
        })
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        return await response.json();
    }
}

export async function getProduct(id) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/product/" + id, {
        method: "get",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status == 400) {
        throw new Error("unauthorized, please login again");
    }
}

export async function getAllProducts() {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/products", {
        method: "get",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status === 401) {
        throw new Error("User Unauthorized. Please login again.");
    } else {
        var errorResponse = await response.json();
        throw new Error(errorResponse.error);
    }
}

export async function deleteProduct() {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/product", {
        method: "delete",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status == 400) {
        throw new Error(
            "token is expired or token is unauthorized, please login again"
        );
    }
}

export async function addSettings(requestBody) {
    try {
        const token = localStorage.getItem("token");
        const response = await fetch("/v1/createSettings", {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: token
            },
            body: JSON.stringify(requestBody)
        });
        if (response.status === 200) {
            return await response.json();
        } else if (response.status === 400 || 422) {
            let errorResponse = await response.json();
            throw new Error(errorResponse.error);
        } else if (response.status === 401) {
            let errorResponse = await response.json();
            throw new Error(errorResponse.error);
        } else if (response.status === 500 || 504) {
            let errorResponse = await response.json();
            throw new Error(errorResponse.error);
        }
    } catch (e) {
        throw e;
    }
}

export async function updateSettings(id, requestBody) {
    try {
        const token = localStorage.getItem("token");
        const response = await fetch("/v1/settings/" + id, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: token
            },
            body: JSON.stringify(requestBody)
        });
        if (response.status === 200) {
            return await response.json();
        } else if (response.status === 400 || 422) {
            let errorResponse = await response.json();
            throw new Error(errorResponse.error);
        } else if (response.status === 401) {
            let errorResponse = await response.json();
            throw new Error(errorResponse.error);
        } else if (response.status === 500 || 504) {
            let errorResponse = await response.json();
            throw new Error(errorResponse.error);
        }
    } catch (e) {
        throw e;
    }
}

export async function createSettings() {
    try {
        const token = localStorage.getItem("token");
        const response = await fetch("/v1/createSettings", {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: token
            }
        });
        if (response.status === 200) {
            return await response.json();
        } else if (response.status === 400 || 422) {
            let errorResponse = await response.json();
            throw new Error(errorResponse.error);
        } else if (response.status === 401) {
            let errorResponse = await response.json();
            throw new Error(errorResponse.error);
        } else if (response.status === 500 || 504) {
            let errorResponse = await response.json();
            throw new Error(errorResponse.error);
        }
        {
            return response;
        }
    } catch (e) {
        throw e;
    }
}

export async function getSettings() {
    try {
        const token = localStorage.getItem("token");
        const response = await fetch("/v1/setting", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token
            }
        });
        if (response.status === 200) {
            return await response.json();
        } else if (response.status === 400 || 422) {
            let errorResponse = await response.json();
            throw new Error(errorResponse.error);
        } else if (response.status === 401) {
            let errorResponse = await response.json();
            throw new Error(errorResponse.error);
        } else if (response.status === 500 || 504) {
            let errorResponse = await response.json();
            throw new Error(errorResponse.error);
        }
        {
            return response;
        }
    } catch (e) {
        throw e;
    }
}

//ActiveSubscription
export async function addSubscription(body) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/subscription", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(body)
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status === 401) {
        throw new Error(
            "token is expired or token is unauthorized, please login again"
        );
    } else {
        let message = await response.json();
        throw new Error(message.error);
    }
}

export async function addQuickSubscription(body) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/quicksubscription", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(body)
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        let Errors = await response.json();
        throw new Error(Errors.error);
    }
}

export async function addQuickSubscriptionReminCount() {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/quicksubscriptionremaincount", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify()
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        let Errors = await response.json();
        throw new Error(Errors.error);
    }
}

export async function getAllSubscription() {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/subscriptions", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status === 401) {
        throw new Error(
            "token is expired or token is unauthorized, please login again"
        );
    } else {
        let message = await response.json();
        throw new Error(message.error);
    }
}

export async function getSubscription(id) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/subscription/" + id, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status === 401) {
        throw new Error(
            "token is expired or token is unauthorized, please login again"
        );
    } else {
        let message = await response.json();
        throw new Error(message.error);
    }
}
// --------------------------------------------
export async function getUsage() {
    console.log("Usage Mounted start api");

    const token = localStorage.getItem("token");
    const response = await fetch("/v1/usagedata", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });

    if (response.status === 200) {
        return await response.json();
    } else if (response.status === 401) {
        throw new Error(
            "token is expired or token is unauthorized, please login again"
        );
    } else {
        let message = await response.json();
        throw new Error(message.error);
    }
}

export async function addSubscriptionUsage(body) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/usagematrix", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(body)
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status === 401) {
        throw new Error(
            "token is expired or token is unauthorized, please login again"
        );
    } else {
        let message = await response.json();
        throw new Error(message.error);
    }
}
export async function deleteSubscriptionUsage(body) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/usagematrix", {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(body)
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        let error = await response.json();
        throw new Error(error.error);
    }
}

// -----------------------------------------------------
export async function updateSubscription(id, body) {
    const token = localStorage.getItem("token");

    const response = await fetch("/v1/subscription/" + id, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(body)
    });

    if (response.status === 200) {
        return await response.json();
    } else if (response.status === 401) {
        throw new Error(
            "token is expired or token is unauthorized, please login again"
        );
    } else {
        let message = await response.json();
        throw new Error(message.error);
    }
}

export async function deleteSubscription(id) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/subscription/" + id, {
        method: "delete",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status === 400) {
        throw new Error(
            "token is expired or token is unauthorized, please login again"
        );
    }
}

export async function getCancelledSubscription() {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/inactivesubscriptions", {
        method: "Get",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status === 401) {
        throw new Error(
            "token is expired or token is unauthorized, please login again"
        );
    } else {
        let message = await response.json();
        throw new Error(message.error);
    }
}

export async function createSubscriptionHistory(body) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/subscriptionhistory", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(body)
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status == 400) {
        throw new Error("unauthorized, please login again");
    }
}

export async function updateSubscriptionHistory(
    id,
    paymentDate,
    paymentAmount,
    paymentCurrency
) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/subscriptionhistoryupdate/" + id, {
        method: "put",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify({
            paymentDate: paymentDate,
            paymentAmount: paymentAmount,
            paymentCurrency: paymentCurrency
        })
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status == 404) {
        throw new Error("subscription history not Found");
    } else {
        return response;
    }
}

export async function updateSubscriptionHistorystatus(id, reconciled) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/subscriptionhistoryupdatestatus/" + id, {
        method: "put",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify({
            reconciled: reconciled
        })
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status == 404) {
        throw new Error("subscription history not Found");
    } else {
        return response;
    }
}

export async function getAllSubscriptionHistories(id) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/subscriptionhistories/" + id, {
        method: "get",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status == 400) {
        throw new Error("User Unauthorized, please login again");
    }
}

//PaymentReminders

export async function createPaymentReminder(body) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/reminder", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(body)
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status == 400) {
        throw new Error("unauthorized, please login again");
    }
}

export async function getAllPaymentReminder() {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/reminders", {
        method: "get",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status == 400) {
        throw new Error("User Unauthorized, please login again");
    }
}

export async function deletePaymentReminder(id) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/reminder/" + id, {
        method: "delete",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status == 400) {
        throw new Error(
            "token is expired or token is unauthorized, please login again"
        );
    }
}

//Dashboard Summary

export async function getDashboardSummary() {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/summary", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });

    if (response.status === 200) {
        return await response.json();
    } else if (response.status === 401) {
        throw new Error("User Unauthorized. Please login again.");
    } else {
        var errorResponse = await response.json();
        throw new Error(errorResponse.error);
    }
}

//Audit Response

export async function createAudit(body) {
    const token = localStorage.getItem("token");
    const response = await fetch("v1/employeeaudit", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(body)
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status == 400) {
        throw new Error("unauthorized, please login again");
    }
}

export async function getAudit() {
    const token = localStorage.getItem("token");

    const response = await fetch("/v1/audit", {
        method: "get",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });

    if (response.status === 200) {
        return await response.json();
    } else if (response.status === 401) {
        throw new Error(
            "token is expired or token is unauthorized, please login again"
        );
    } else {
        let errorResponse = await response.json();
        throw new Error(errorResponse.error);
    }
}

export async function updateAudit(update, id) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/employeeauditupdate/" + id, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(update)
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status == 400) {
        throw new Error(" unauthorized, please login again");
    } else if (response.status == 400) {
        return await response.json();
    }
}

export async function getEmployeeSubscription(id) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/employeeauditresponse/" + id, {
        method: "get",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status == 400) {
        throw new Error("unauthorized, please login again");
    }
}

//check pilot user
export async function verifyPilotUser(email, code) {
    const response = await fetch("/v1/verifypilotuser/" + email + "/" + code, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        }
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status == 400) {
        throw new Error("unauthorized, please login again");
    } else {
        return await response.json();
    }
}

//get Calendar
export async function getCalendar() {
    const token = localStorage.getItem("token");

    const response = await fetch("/v1/getCalender/month", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        throw new Error("unauthorized, please login again");
    }
}

export async function getCalenderDetails() {
    const token = localStorage.getItem("token");

    const response = await fetch("/v1/getCalenderDetails", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        throw new Error("unauthorized, please login again");
    }
}

export async function getBarChartDetails() {
    const token = localStorage.getItem("token");

    const response = await fetch("/v1/getbarchartdata ", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        throw new Error(errors[response.status]);
    }
}

//get doughnut chart data
export async function getDoughnutData() {
    const token = localStorage.getItem("token");

    const response = await fetch("/v1/getdoughnutdata ", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        throw new Error(errors[response.status]);
    }
}

//get tag data

//get doughnut chart data
export async function getTagData(tagId) {
    const token = localStorage.getItem("token");

    const response = await fetch("/v1/gettagdetails/" + tagId, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        return await response.json();
    }
}

// delete tag

export async function DeletTag(id) {
    const token = localStorage.getItem("token");

    const response = await fetch("/v1/deletetagdetails/" + id, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        throw new Error(errors[response.status]);
    }
}

// update tag

export async function UpdateTagData(tagId, body) {
    const token = localStorage.getItem("token");

    const response = await fetch("/v1/updatetagdetails/" + tagId, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(body)
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        return await response.json();
    }
}

export async function ChangeCustomTags(body) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/changecustomtag/", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(body)
    });
    console.log("Changed Tag ", response);
    if (response.status === 200) {
        return await response.json();
    } else {
        return await response.json();
    }
}

// what's up registeration

export async function whatsappSendRequest(body) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/addwhatsapp", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(body)
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        return await response.json();
    }
}

export async function DeleteHistoryData(id) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/deletehistory/" + id, {
        method: "delete",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    try {
        if (response.status === 200) {
            return await response.json();
        } else if (response.status == 400) {
            throw new Error(
                "token is expired or token is unauthorized, please login again"
            );
        }
    } catch (e) { }
}

export async function AddCustomReminderDetail(body) {
    const token = localStorage.getItem("token");

    const response = await fetch("/v1/customreminder", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(body)
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        let result = await response.json();
        throw new Error(result.error);
    }
}

export async function GetCustomReminderDetail() {
    const token = localStorage.getItem("token");

    const response = await fetch("/v1/customreminder", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        let result = await response.json();
        throw new Error(result.error);
    }
}

export async function DeleteCustomReminderDetail(id) {
    const token = localStorage.getItem("token");

    const response = await fetch(`/v1/customreminder/${id}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        let result = await response.json();
        throw new Error(result.error);
    }
}

export async function SlackIntegrate(code) {
    const token = localStorage.getItem("token");

    const response = await fetch("/v1/salckintegrate/" + code, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        let result = await response.json();
        throw new Error(result.error);
    }
}

export async function getAllEmployee() {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/employees/", {
        method: "get",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status == 400) {
        throw new Error("unauthorized, please login again");
    }
}

export async function getProfile() {
    const token = localStorage.getItem('token')
    const response = await fetch('/v1/organization', {
        method: 'get',
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status == 400) {
        throw new Error(
            "token is expired or token is unauthorized, please login again"
        );
    } else {
        return response;
    }
}




export async function editBusiness(id, body) {
    const token = localStorage.getItem('token')
    const response = await fetch('/v1/organization/' + id, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
        },
        body: JSON.stringify(body)
    })
    if (response.status === 200) {
        return await response.json()
    } else if (response.status == 400) {
        throw new Error("token is expired or token is unauthorized, please login again")
    } else {
        return response
    }
}




export async function deleteEmployee(id) {
    const token = localStorage.getItem("token");
    console.log(`Token ${token}`);
    const response = await fetch("v1/employee/" + id, {
        method: "delete",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    console.log("Deleted employee ", response);
    if (response.status === 200) {
        return await response.json();
    } else if (response.status == 400) {
        throw new Error(
            "token is expired or token is unauthorized, please login again"
        );
    } else {
        return response;
    }
}

export async function updateEmployee(id, update) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/employee/" + id, {
        method: "put",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(update)
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status === 401) {
        throw new Error(
            "token is expired or token is unauthorized, please login again"
        );
    } else {
        let errorResponse = await response.json();
        throw new Error(errorResponse.error);
    }
}

export async function addDepartment(name) {
    const token = localStorage.getItem("token");
    const response = await fetch("v1/department", {
        method: "post",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify({
            name: name
        })
    });
    console.log("addDepartment Response", response);
    if (response.status === 200) {
        return await response.json();
    } else if (response.status === 401) {
        throw new Error("User Unauthorized. Please login again.");
    } else {
        let errorResponse = await response.json();
        throw new Error(errorResponse.error);
    }
}

export async function getAllDepartments() {
    const token = localStorage.getItem("token");
    const response = await fetch("v1/departments", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });

    if (response.status === 200) {
        return await response.json();
    } else if (response.status === 401) {
        throw new Error("User Unauthorized. Please login again.");
    } else {
        var errorResponse = await response.json();
        throw new Error(errorResponse.error);
    }
}

//Employee
export async function addEmployee(body) {
    const token = localStorage.getItem("token");
    const response = await fetch("v1/employee", {
        method: "post",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(body)
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status == 400) {
        throw new Error(
            "token is expired or token is unauthorized, please login again"
        );
    }
    {
        var errorResponse = await response.json();
        throw new Error(errorResponse.error);
    }
}

export async function getAllNotifications() {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/notifications", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        var errorResponse = await response.json();
        throw new Error(errorResponse.error);
    }
}

export async function updateNotification(id, body) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/notification/" + id, {
        method: "put",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(body)
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        var errorResponse = await response.json();
        throw new Error(errorResponse.error);
    }
}

// ADMIN
export async function addAdmin(data) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/addadmin", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(data)
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        let errorResponse = await response.json();
        throw new Error(errorResponse.error);
    }
}

export async function VerifyAdmin(email, code) {
    const response = await fetch(`/v1/verifyadmin/${email}/${code}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        return await response.json();
    }
}

export async function RegisterAdmin(data) {
    const response = await fetch("/v1/registeradmin", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status == 402) {
        throw new Error("unauthorized, please login again");
    } else if (response.status == 404) {
        throw new Error("User Not Found");
    } else if (response.status == 500) {
        throw new Error("Invalid input please refresh first");
    } else if (response.status == 400) {
        throw new Error("Server Error");
    } else if (response.status == 403) {
        throw new Error("User Exist Plese Refresh First");
    } else if (response.status == 429) {
        throw new Error("Limit Exceeded Plese Refresh Page");
    } else {
        return await response.json();
    }
}

export async function removeAdmin(id) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/removeadmin/" + id, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        let errorResponse = await response.json();
        throw new Error(errorResponse.error);
    }
}

export async function getEmployeesForAdmin() {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/getemployeestoadmin", {
        method: "get",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    if (response.status == 200) {
        return await response.json();
    } else {
        let errorResponse = await response.json();
        throw new Error(errorResponse.error);
    }
}

export async function getAdmins() {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/getadmins", {
        method: "get",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    if (response.status == 200) {
        return await response.json();
    } else {
        let errorResponse = await response.json();
        throw new Error(errorResponse.error);
    }
}

export async function setAudit(data) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/setaudit", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(data)
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        let errorResponse = await response.json();
        throw new Error(errorResponse.error);
    }
}

export async function setSelfService(data) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/userselfservice", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(data)
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        let errorResponse = await response.json();
        throw new Error(errorResponse.error);
    }
}

// get history

export async function getHistory() {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/getHistories", {
        method: "get",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status == 401) {
        throw new Error(
            "token is expired or token is unauthorized, please login again"
        );
    } else {
        let result = await response.json();
        throw new Error(result.error);
    }
}

export async function updateSubscriptionVirtualstatus(id, virtual) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/subscriptionVirtualupdatestatus/" + id, {
        method: "put",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify({
            virtual: virtual
        })
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status == 404) {
        throw new Error("subscription for virtual not Found");
    } else if (response.status == 401) {
        throw new Error(
            "token is expired or token is unauthorized, please login again"
        );
    } else {
        let result = await response.json();
        throw new Error(result.error);
    }
}

export async function updateAdmin(id, data) {
    const token = localStorage.getItem("token");
    const response = await fetch(`/v1/admin/${id}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(data)
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        let errorResponse = await response.json();
        throw new Error(errorResponse.error);
    }
}

export async function AddFeedback(body) {
    const token = localStorage.getItem("token");

    const response = await fetch("/v1/feedback", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(body)
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status == 401) {
        throw new Error(
            "token is expired or token is unauthorized, please login again"
        );
    } else {
        let result = await response.json();
        throw new Error(result.error);
    }
}

export async function DeleteUser() {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/userdeleterequest", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        return await response.json();
    }
}

export async function getGsuitUser(body) {
    const token = localStorage.getItem("token");

    const response = await fetch("/v1/getGsuit", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(body)
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status == 401) {
        throw new Error(
            "token is expired or token is unauthorized, please login again"
        );
    } else {
        let result = await response.json();
        throw new Error(result.error);
    }
}

export async function addGsuitUser(body) {
    const token = localStorage.getItem("token");

    const response = await fetch("/v1/addgsuit", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(body)
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status == 401) {
        throw new Error(
            "token is expired or token is unauthorized, please login again"
        );
    } else {
        let result = await response.json();
        throw new Error(result.error);
    }
}

export async function upload(url) {
    const response = await fetch("/v1/uploadUserSubscription", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ url })
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status === 400) {
        throw new Error("Error");
    }
}

export async function activateTFA(body) {
    const token = localStorage.getItem("token");

    const response = await fetch("v1/authyregister", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(body)
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        let message = await response.json();
        throw new Error(message.error);
    }
}

export async function sendOTP(token) {
    const response = await fetch("v1/authyotp", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        let message = await response.json();
        throw new Error(message.error);
    }
}

export async function ringOTP(token) {
    const response = await fetch("v1/authycallotp", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        let message = await response.json();
        throw new Error(message.error);
    }
}

export async function deactivateTFA() {
    const token = localStorage.getItem("token");

    const response = await fetch("v1/deactivatetfa", {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        let message = await response.json();
        throw new Error(message.error);
    }
}

export async function verifyOTP(token, body) {
    const response = await fetch("v1/authyotpverify", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },

        body: JSON.stringify(body)
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        let message = await response.json();
        throw new Error(message.error);
    }
}

export async function enableTFA(body) {
    const token = localStorage.getItem("token");

    const response = await fetch("v1/enabletfa", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },

        body: JSON.stringify(body)
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        let message = await response.json();
        throw new Error(message.error);
    }
}

export async function ImportData(body) {
    const token = localStorage.getItem("token");

    const response = await fetch("v1/importsubscription", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },

        body: JSON.stringify(body)
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        let message = await response.json();
        throw new Error(message.error);
    }
}

export async function AddCustomField(body) {
    const token = localStorage.getItem("token");

    const response = await fetch("v1/addCustomField", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },

        body: JSON.stringify(body)
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        let message = await response.json();
        throw new Error(message.error);
    }
}

export async function DeleteCustomField(name) {
    const token = localStorage.getItem("token");

    const response = await fetch("v1/deleteCustomField", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },

        body: JSON.stringify({ name: name })
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        let message = await response.json();
        throw new Error(message.error);
    }
}

export async function DeleteSubscriptionCustomField(id, name) {
    const token = localStorage.getItem("token");

    const response = await fetch("/v1/deleteSubscriptionCustomField/" + id, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },

        body: JSON.stringify({ name: name })
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        let message = await response.json();
        throw new Error(message.error);
    }
}

export async function saveCustomFields(id, data) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/editCustomField/" + id, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },

        body: JSON.stringify(data)
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        let message = await response.json();
        throw new Error(message.error);
    }
}

export async function ScanQrCode() {
    const token = localStorage.getItem("token");

    const response = await fetch("v1/googleQr", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },

    });
    if (response.status === 200) {
        return await response.json();
    } else {
        let message = await response.json();
        throw new Error(message.error);
    }
}

export async function VerifyQr(verificationCode) {
    const token = localStorage.getItem("token");

    const response = await fetch("v1/verifyQr", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify({ token: verificationCode })

    });
    if (response.status === 200) {
        return await response.json();
    } else {
        let message = await response.json();
        throw new Error(message.error);
    }
}

export async function VerifyCode(verificationCode) {
    const token = localStorage.getItem("token");

    const response = await fetch("v1/VerifyCode", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify({ verifyCode:verificationCode})

    });
    if (response.status === 200) {
        return await response.json();
    } else {
        let message = await response.json();
        throw new Error(message.error);
    }
}

export async function disableAuth() {
    const token = localStorage.getItem("token");

    const response = await fetch("v1/disableAuth", {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },

    });
    if (response.status === 200) {
        return await response.json();
    } else {
        let message = await response.json();
        throw new Error(message.error);
    }
}

export async function verifyToken(token, body) {
    const response = await fetch("v1/verifyToken", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },

        body: JSON.stringify(body)
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        let message = await response.json();
        throw new Error(message.error);
    }
}

export async function oAuthGetToken(body) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/addToken", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },

        body: JSON.stringify(body)
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        let message = await response.json();
        throw new Error(message.error);
    }
}

export async function getCredentials() {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/getCredentials", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },

    });
    if (response.status === 200) {
        return await response.json();
    } else {
        let message = await response.json();
        throw new Error(message.error);
    }
}

export async function updateCredentials(id, body) {
    const token = localStorage.getItem("token");
    const response = await fetch("v1/updateCredentials/" + id, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(body)
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        let message = await response.json();
        throw new Error(message.error);
    }
}

export async function deleteCredentials(id) {
    const token = localStorage.getItem("token");
    const response = await fetch("v1/deleteCredential/" + id, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        let message = await response.json();
        throw new Error(message.error);
    }
}

export async function AddTicket(body) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/addTicket", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(body)
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        return await response.json();
    }
}

export async function getAllTickets() {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/getAllTickets", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        return await response.json();
    }
}
export async function DeleteTicket(id) {
    const token = localStorage.getItem("token");
    const response = await fetch("/v1/deleteTicket/" + id, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        return await response.json();
    }
}


//get tickets
export async function getTickets() {
    const token = localStorage.getItem("token");

    const response = await fetch("/v1/ticketsget", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        throw new Error(errors[response.status]);
    }
}
