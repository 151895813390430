
import {
    GET_EMPLOYEE,
    GET_EMPLOYEE_RESPONSE,
    GET_EMPLOYEE_ERROR,
    EMPLOYEE_UPDATE_RESPONSE_CLEAR,
    EMPLOYEE_UPDATE_RESPONSE,
    EMPLOYEE_UPDATE_ERROR,
    EMPLOYEE_UPDATE_ERROR_CLEAR,
    EMPLOYEE_UPDATE_MODAL_CLOSED,
    ADD_EMPLOYEE,
    ADD_EMPLOYEE_RESPONSE,
    ADD_EMPLOYEE_RESPONSE_CLEAR,
    ADD_EMPLOYEE_ERROR,
    ADD_EMPLOYEE_ERROR_CLEAR,
    ADD_GSUIT_EMPLOYEES,
    ADD_GSUIT_EMPLOYEES_RESPONSE,
    ADD_GSUIT_EMPLOYEES_RESPONSE_CLEAR,
    ADD_GSUIT_EMPLOYEES_ERROR,
    CLEAR_EMPLOYEE
} from './EmployeeTypes'

const initialState = {
    employees: [],
    isLoading: false,
    isLoaded: false,
    isEmployeeUpdated: false,
    employeeUpdateError: '',
    departmentCloseModal: false,
    isAdding:false,
    addError:'',
    isAdded:false,
    isGsuitAdding:false,
    isGsuitAdded:false
}

const EmployeeReducer = (state = initialState, action) => {

    switch (action.type) {
        case ADD_EMPLOYEE: return {...state,isAdding:true}
        case ADD_EMPLOYEE_RESPONSE :
        return {...state,isAdding:false,isAdded:true,employees:[action.payload,...state.employees]}
        case ADD_EMPLOYEE_ERROR : return {...state,isAdding:false,addError:action.payload}
        case ADD_EMPLOYEE_ERROR_CLEAR : return {...state,isAdding:false,addError:''}
        case ADD_EMPLOYEE_RESPONSE_CLEAR : return {...state,isAdded:false}

        case EMPLOYEE_UPDATE_ERROR_CLEAR:
            return { ...state, employeeUpdateError: '' };
        case EMPLOYEE_UPDATE_ERROR:
            return { ...state, employeeUpdateError: action.payload };
        case EMPLOYEE_UPDATE_RESPONSE:
            let employee_list = state.employees.map((data) => {
                if (data._id === action.payload._id) {
                    return action.payload
                }
                else {
                    return data
                }
            })

            return { ...state, isEmployeeUpdated: true, employees: employee_list, departmentCloseModal: true };
        case EMPLOYEE_UPDATE_MODAL_CLOSED:
            return { ...state, departmentCloseModal: false }
        case EMPLOYEE_UPDATE_RESPONSE_CLEAR:
            return { ...state, isEmployeeUpdated: false }
        case GET_EMPLOYEE:
            return { ...state, isLoading: true, isLoaded: true }

        case GET_EMPLOYEE_RESPONSE:
            return { ...state, isLoading: false, isLoaded: true, employees: action.payload }
        
        case ADD_GSUIT_EMPLOYEES:
            return { ...state, isGsuitAdding: true }
        
        case ADD_GSUIT_EMPLOYEES_RESPONSE:
                return { ...state, isGsuitAdding: false,employees:[...action.payload,...state.employees],isGsuitAdded:true }
        case ADD_GSUIT_EMPLOYEES_RESPONSE_CLEAR:
                    return { ...state,isGsuitAdded:false }
        case ADD_GSUIT_EMPLOYEES_ERROR:
                    return { ...state, isGsuitAdding: false }
        case CLEAR_EMPLOYEE :
            return {
                employees: [],
                isLoading: false,
                isLoaded: false,
                isEmployeeUpdated: false,
                employeeUpdateError: '',
                departmentCloseModal: false,
                isAdding:false,
                addError:'',
                isAdded:false,
                isGsuitAdding:false,
                isGsuitAdded:false
            }
        default: return state;

    }
}

export default EmployeeReducer