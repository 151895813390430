import {
    GET_ALL_TAGS,
    TAGS_LOADED,
    ADD_TAG_DATA_SIDE_NAV,
    ADD_TAG_DATA_RESPONSE, ADD_TAG_DATA_RESPONSE_CLEAR,
    ADD_TAG_DATA_ERROR, ADD_TAG_DATA_ERROR_CLEAR,
    DELETE_TAG_DATA_RESPONSE,
    UPDATE_TAG_DATA_RESPONSE,
    UPDATE_TAG_DATA_ERROR,
    UPDATE_TAG_DATA_ERROR_CLEAR
    , UPDATE_TAG_DATA_RESPONSE_CLEAR,
    UPDATE_TAG_DATA,
    CLEAR_ALL_TAGS
} from './TagTypes'

const initialState = {
    tags: [],
    total: '',
    inactive: '',
    unassigned: '',
    isLoaded: false,
    isSideNavTagAdding: false,
    isTagAdded: false,
    sideNavTagError: '',
    sideNavTagUpdateError: '',
    sideNavTagUpdated: false,
    sideNavTagUpdating: false
}


const TagReducer = (state = initialState, action) => {

    switch (action.type) {
        case UPDATE_TAG_DATA:
            return { ...state, sideNavTagUpdating: true }

        case UPDATE_TAG_DATA_RESPONSE:
            let updateTags = state.tags
            updateTags.map((data, index) => {

                if (data._id == action.payload._id) {

                    updateTags[index] = { _id: action.payload._id, tagName: action.payload.tagName, tagColor: action.payload.tagColor, subscriptions: action.payload.subscriptions }
                }

            })

            return { ...state, tags: updateTags, sideNavTagUpdated: true, sideNavTagUpdating: false }

        case UPDATE_TAG_DATA_RESPONSE_CLEAR:
            return { ...state, sideNavTagUpdated: false }

        case UPDATE_TAG_DATA_ERROR:
            return { ...state, sideNavTagUpdateError: action.payload, sideNavTagUpdating: false }
        case UPDATE_TAG_DATA_ERROR_CLEAR:
            return { ...state, sideNavTagUpdateError: '' }

        case ADD_TAG_DATA_RESPONSE:
            let newTags = [...state.tags, action.payload]
            return { ...state, tags: newTags, isSideNavTagAdding: false, isTagAdded: true }

        case ADD_TAG_DATA_RESPONSE_CLEAR:

            return { ...state, isTagAdded: false }




        case ADD_TAG_DATA_ERROR:
            return { ...state, isSideNavTagAdding: false, sideNavTagError: action.payload }

        case ADD_TAG_DATA_ERROR_CLEAR:
            return { ...state, sideNavTagError: '' }
        case ADD_TAG_DATA_SIDE_NAV:
            return { ...state, isSideNavTagAdding: true }


        case TAGS_LOADED:
            return { ...state, isLoaded: true }
        case GET_ALL_TAGS:
            return { ...state, isLoaded: true, ...action.payload }

        case CLEAR_ALL_TAGS:
            return {
                tags: [],
                total: '',
                inactive: '',
                unassigned: '',
                isLoaded: false,
                isSideNavTagAdding: false,
                isTagAdded: false,
                sideNavTagError: '',
                sideNavTagUpdateError: '',
                sideNavTagUpdated: false,
                sideNavTagUpdating: false
            }

        default: return state;

    }

}
export default TagReducer