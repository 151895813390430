import {

    LOGIN,
    LOGIN_RESPONSE,
    LOGIN_ERROR,
    CLEAR_LOGIN_ERROR,
    GET_USER_DATA,
    GET_USER_DATA_ERROR,
    GET_USER_DATA_LOADED,
    UPDATE_USER_DATA,
    UPDATE_USER_DATA_RESPOSE,
    UPDATE_USER_DATA_RESPOSE_CLEAR,
    LOGOUT

} from './UserTypes'

const initialState = {
    user: {},
    isLoading: false,
    isLoaded: false,
    isLogin: true,
    loginError: '',
    planType:'',
    hashmail:''
}

const UserReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_USER_DATA_LOADED:
            return { ...state, isLoaded: true }
        case GET_USER_DATA:
            return { ...state, user: action.payload,hashmail:action.email_hash,planType:action.payload.userType.planName, isLogin: true }
        case GET_USER_DATA_ERROR:
            return { ...state,  isLogin: false }

        case UPDATE_USER_DATA :
            return {...state ,isUpdating:true}

        case UPDATE_USER_DATA_RESPOSE :
            return {...state ,isUpdating:false,isUpdated:true}

        case UPDATE_USER_DATA_RESPOSE_CLEAR :
            return {...state ,isUpdated:false}

        case LOGIN:
            return { ...state, isLoading: true }
        case LOGIN_ERROR:
            return { ...state, user: {}, isLoading: false, isLogin: false, loginError: action.payload }

        case CLEAR_LOGIN_ERROR:
            return { ...state, loginError: '' }

        case LOGIN_RESPONSE:
            localStorage.setItem('token', action.payload)
            return { ...state, isLoading: false, isLogin: true, loginError: '' }

        case LOGOUT:
            return {
                user: {},
                isLoading: false,
                isLoaded: false,
                isLogin: true,
                loginError: '',
                planType:'',
                hashmail:''
            }

        default: return state;
    }
}

export default UserReducer

