import React, { useEffect } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import './App.scss'
import ProtectedRoute from './services/auth/ProtectedRoute'
import { AuthProvider } from './services/auth/AuthContext'
import { GlobalContextProvider } from './services/GlobalContext'
import { SnackbarProvider } from 'notistack'

import "antd/dist/antd.css";
import "antd/es/style/themes/default.less";

const loading = () => <div
    className="animated fadeIn pt-3 text-center">Loading...</div>
// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'))

// Pages
const AddNewEmail = React.lazy(() => import('./views/Pages/AddNewEmail/AddNewEmail'));
const Login = React.lazy(() => import('./views/Pages/Login'))
const Register = React.lazy(() => import('./views/Pages/Register'))
const Signup = React.lazy(() => import("./views/Pages/Register/SignUp"))
const Page404 = React.lazy(() => import('./views/Pages/Page404'))
const Page500 = React.lazy(() => import('./views/Pages/Page500'))
const ForgetPassword = React.lazy(() => import('./views/Pages/ForgetPassword/ForgetPassword'))
const ResetPassword = React.lazy(() => import('./views/Pages/ForgetPassword/ResetPassword'))
const EmailSent = React.lazy(() => import('./views/Pages/Register/EmailSent'))

const PilotUserRegister = React.lazy(() => import('./views/Pages/Register/PilotUserRegister'))

const OfflineMessageStyle = {
    marginTop: '0px',
    borderRadius: '0px',
    background: 'rgb(222, 53, 11)',
    zIndex: '200',
    marginLeft: '0px',
    width: '100%',
    padding: '5px'

}

const OfflineMessageTextStyle = {
    marginLeft: 'auto',
    color: 'white'
}

const OfflineMessageTextIconStyle = {
    fontSize: '15px',
    marginRight: '5px'
}

const OnlineMessageStyle = {
    marginTop: '0px',
    borderRadius: '0px',
    background: 'rgb(69, 151, 44)',
    zIndex: '200',
    marginLeft: '0px',
    width: '100%',
    padding: '5px'

}


const App = (props) => {

    useEffect(() => {
        const isLocalhost = Boolean(
            window.location.hostname === 'localhost' ||
            // [::1] is the IPv6 localhost address.
            window.location.hostname === '[::1]' ||
            // 127.0.0.1/8 is considered localhost for IPv4.
            window.location.hostname.match(
                /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
            )
        );
        if (!isLocalhost && window.location.protocol === "http:") {

            window.location = `https://${window.location.host}${window.location.pathname}`
        }

    }, [])






    return (

        <SnackbarProvider maxSnack={3} preventDuplicate={true} anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}>



            <AuthProvider>
                <GlobalContextProvider>
                    <BrowserRouter>
                        <React.Suspense fallback={loading()}>
                            <Switch>
                                <Route exact path="/login" name="Login Page"
                                    render={props => <Login {...props} />} />
                                <Route exact={true} path={"/register/:planName"} name="Verify Pilot User" render={props => <Register {...props} />} />
                                <Route exact={true} path={"/register"} name="Verify Pilot User" render={props => <Register {...props} />} />
                                <Route exact={true} path={"/signup/:planName/:period"} name="Signup User" render={props => <Signup {...props} />} />
                                <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
                                <Route exact path="/500" name="Page 500"
                                    render={props => <Page500 {...props} />} />
                                <Route exact path="/forgotpassword" name="Forget Password" render={props => <ForgetPassword {...props} />} />

                                <Route exact={true} path={"/verifypilotuser/:email/:code"} name="Verify Pilot User"
                                    render={props => <PilotUserRegister {...props} />} />

                                <Route exact={true} path={"/resetpassword/:code"}
                                    name="Reset Password"
                                    render={props => <ResetPassword {...props} />} />
                                <Route exact={true} path={"/activateemail/:id/:code"}
                                    name="Reset Password"
                                    render={props => <AddNewEmail {...props} />} />

                                <Route exact={true} path={"/verify/:code"} name="Verify email"
                                    render={props => <EmailSent {...props} />} />






                                <ProtectedRoute path="/" name="Home"
                                    component={DefaultLayout} />
                            </Switch>
                        </React.Suspense>

                    </BrowserRouter>
                </GlobalContextProvider>
            </AuthProvider>
        </SnackbarProvider>
    )

}

export default App
