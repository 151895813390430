
export const GETSUBSCRIPTIONS = 'GETSUBSCRIPTIONS'
export const CLEARGETSUBSCRIPTIONS = 'CLEARGETSUBSCRIPTIONS'

export const SETFIRSTLOADED = 'SETFIRSTLOADED'
export const GETSUBSCRIPTIONSLOADNIG = 'GETSUBSCRIPTIONSLOADNIG'
export const CLEARGETSUBSCRIPTIONSLOADNIG = 'CLEARGETSUBSCRIPTIONSLOADNIG'

export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION'
export const CREATE_SUBSCRIPTION_RESPONSE = 'CREATE_SUBSCRIPTION_RESPONSE'
export const CREATE_SUBSCRIPTION_RESPONSE_CLEAR = 'CREATE_SUBSCRIPTION_RESPONSE_CLEAR'
export const CREATE_SUBSCRIPTION_ERROR = 'CREATE_SUBSCRIPTION_ERROR'
export const CREATE_SUBSCRIPTION_ERROR_CLEAR = 'CREATE_SUBSCRIPTION_ERROR_CLEAR'

export const EDIT_SUBSCRIPTION = 'EDIT_SUBSCRIPTION'
export const EDIT_SUBSCRIPTION_RESPONSE = 'EDIT_SUBSCRIPTION_RESPONSE'
export const EDIT_SUBSCRIPTION_RESPONSE_CLEAR = 'EDIT_SUBSCRIPTION_RESPONSE_CLEAR'
export const EDIT_SUBSCRIPTION_ERROR = 'EDIT_SUBSCRIPTION_ERROR'
export const EDIT_SUBSCRIPTION_ERROR_CLEAR = 'EDIT_SUBSCRIPTION_ERROR_CLEAR'

export const SUBSCRIPTION_CANCELLED = 'SUBSCRIPTION_CANCELLED'
export const SUBSCRIPTION_ADDED = 'SUBSCRIPTION_ADDED'

export const SUBSCRIPTION_CANCELLED_CLEAR = 'SUBSCRIPTION_CANCELLED_CLEAR'


export const CLEARSUBSCRIPTIONS = 'CLEARSUBSCRIPTIONS'
