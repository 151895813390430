
export const GET_ALL_TAGS = 'GET_ALL_TAGS'
export const TAGS_LOADED = 'TAGS_LOADED'

export const ADD_TAG_DATA = 'ADD_TAG_DATA'
export const ADD_TAG_DATA_RESPONSE = 'ADD_TAG_DATA_RESPONSE'
export const ADD_TAG_DATA_RESPONSE_CLEAR = 'ADD_TAG_DATA_RESPONSE_CLEAR'
export const ADD_TAG_DATA_ERROR = 'ADD_TAG_DATA_ERROR'
export const ADD_TAG_DATA_ERROR_CLEAR = 'ADD_TAG_DATA_ERROR_CLEAR'
export const ADD_TAG_DATA_SIDE_NAV='ADD_TAG_DATA_SIDE_NAV'

export const DELETE_TAG_DATA = 'DELETE_TAG_DATA'
export const DELETE_TAG_DATA_RESPONSE = 'DELETE_TAG_DATA_RESPONSE'
export const DELETE_TAG_DATA_RESPONSE_CLEAR = 'DELETE_TAG_DATA_RESPONSE_CLEAR'
export const DELETE_TAG_DATA_ERROR = 'DELETE_TAG_DATA_ERROR'
export const DELETE_TAG_DATA_ERROR_CLEAR = 'DELETE_TAG_DATA_ERROR_CLEAR'

export const UPDATE_TAG_DATA = 'UPDATE_TAG_DATA'
export const UPDATE_TAG_DATA_RESPONSE = 'UPDATE_TAG_DATA_RESPONSE'
export const UPDATE_TAG_DATA_RESPONSE_CLEAR = 'UPDATE_TAG_DATA_RESPONSE_CLEAR'
export const UPDATE_TAG_DATA_ERROR = 'UPDATE_TAG_DATA_ERROR'
export const UPDATE_TAG_DATA_ERROR_CLEAR = 'UPDATE_TAG_DATA_ERROR_CLEAR'

export const CLEAR_ALL_TAGS = 'CLEAR_ALL_TAGS'






