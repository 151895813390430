
import {
    GET_AUDIT_DATAS,
    GET_AUDIT_DATAS_RESPONSE,
    CLEAR_AUDIT_DATAS
    
} from './AuditTypes'

const initialState = {
    data: [],
    isLoading: false,
    isLoaded: false
}

const AuditReducer = (state = initialState, action) => {

    switch (action.type) {

    case GET_AUDIT_DATAS:
        return { ...state, isLoading: true ,isLoaded:true}
        case GET_AUDIT_DATAS_RESPONSE:
            return { ...state, isLoading: false,data:action.payload}
        
        case CLEAR_AUDIT_DATAS :
            return {
                data: [],
                isLoading: false,
                isLoaded: false
            }

    default: return state;
    }
}

export default AuditReducer
