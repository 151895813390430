

import {
    GET_QUICK_SUPSCRIPTION_REMINING_COUNT,
    GET_QUICK_SUPSCRIPTION_REMINING_COUNT_RESPONSE,
    ADD_QUICK_SUBSCRIPTIONS,
    ADD_QUICK_SUBSCRIPTIONS_RESPONSE,
    ADD_QUICK_SUBSCRIPTIONS_RESPONSE_CLEAR,
    ADD_QUICK_SUBSCRIPTIONS_ERROR,
    ADD_QUICK_SUBSCRIPTIONS_ERROR_CLEAR,
    CLEAR_QUICK_SUPSCRIPTION_REMINING_COUNT
} from './QuicksubscriptionTypes'

const initialState = {
    reminingCount: 0,
    countLoading: true,
    countLoaded: false,
    isQuickSubscriptionAdded:false,
    isQuickSubscriptionAdding:false
}



const QuicksubscriptionReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_QUICK_SUPSCRIPTION_REMINING_COUNT:

            return { ...state, countLoading: true, countLoaded: true }
        case GET_QUICK_SUPSCRIPTION_REMINING_COUNT_RESPONSE:

            return { ...state, countLoading: false, reminingCount: action.payload }

        case ADD_QUICK_SUBSCRIPTIONS:

            return { ...state, isQuickSubscriptionAdding: true }

        case ADD_QUICK_SUBSCRIPTIONS_RESPONSE:

            return { ...state, isQuickSubscriptionAdding: false,isQuickSubscriptionAdded:true }

        case ADD_QUICK_SUBSCRIPTIONS_RESPONSE_CLEAR:

            return { ...state, isQuickSubscriptionAdded: false }
        case ADD_QUICK_SUBSCRIPTIONS_ERROR:

            return { ...state, isQuickSubscriptionAdding: false }
        case ADD_QUICK_SUBSCRIPTIONS_ERROR_CLEAR:

            return { ...state, isQuickSubscriptionAdding: false }

        case CLEAR_QUICK_SUPSCRIPTION_REMINING_COUNT :
            return {
                reminingCount: 0,
                countLoading: true,
                countLoaded: false,
                isQuickSubscriptionAdded:false,
                isQuickSubscriptionAdding:false
            }

        default: return state;

    }
}

export default QuicksubscriptionReducer