
import {
    GET_CHART_DATAS,
    GET_CHART_DATAS_RESPONSE,
    CLEAR_CHART_DATAS
    
} from './chartTypes'

const initialState = {
    data: [],
    isLoading: false,
    isLoaded: false
}

const chartReducer = (state = initialState, action) => {

    switch (action.type) {

        case GET_CHART_DATAS:
            return { ...state, isLoading: true,isLoaded:true }
        

        case GET_CHART_DATAS_RESPONSE:
                return { ...state, isLoading: false ,data:action.payload }
        case CLEAR_CHART_DATAS :
            return {
                data: [],
                isLoading: false,
                isLoaded: false
            }
        default: return state;
    }

}

export default chartReducer