
export const GET_QUICK_SUPSCRIPTION_REMINING_COUNT = 'GET_QUICK_SUPSCRIPTION_REMINING_COUNT'

export const GET_QUICK_SUPSCRIPTION_REMINING_COUNT_RESPONSE = 'GET_QUICK_SUPSCRIPTION_REMINING_COUNT_RESPONSE'

export const ADD_QUICK_SUBSCRIPTIONS = 'ADD_QUICK_SUBSCRIPTIONS'

export const ADD_QUICK_SUBSCRIPTIONS_RESPONSE = 'ADD_QUICK_SUBSCRIPTIONS_RESPONSE'

export const ADD_QUICK_SUBSCRIPTIONS_RESPONSE_CLEAR = 'ADD_QUICK_SUBSCRIPTIONS_RESPONSE_CLEAR'

export const ADD_QUICK_SUBSCRIPTIONS_ERROR = 'ADD_QUICK_SUBSCRIPTIONS_ERROR'

export const ADD_QUICK_SUBSCRIPTIONS_ERROR_CLEAR = 'ADD_QUICK_SUBSCRIPTIONS_ERROR_CLEAR'

export const CLEAR_QUICK_SUPSCRIPTION_REMINING_COUNT = 'CLEAR_QUICK_SUPSCRIPTION_REMINING_COUNT'
