import {
    GET_TEAM_RESPONSE, GET_TEAM,
    ADD_TEAM_ERROR, ADD_TEAM_ERROR_CLEAR,
    ADD_TEAM_RESPONSE, ADD_TEAM_RESPONSE_CLEAR,
    CLEAR_TEAM
} from './TeamTypes';

const initialState = {
    departments: [],
    isLoaded: false,
    isAdded: false,
    addError: ''
}

const TeamReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TEAM:
            return { ...state, isLoaded: true }
        case GET_TEAM_RESPONSE:
            return { ...state, departments: action.payload, isLoaded: true }
        case ADD_TEAM_RESPONSE:
            return {
                ...state,
                departments: [{
                    _id: action.payload._id,
                    name: action.payload.name
                }
                    , ...state.departments], isAdded: true
            }

        case ADD_TEAM_RESPONSE_CLEAR:
            return { ...state, isAdded: false }

        case ADD_TEAM_ERROR:
            return { ...state, addError: action.payload }
        case ADD_TEAM_ERROR_CLEAR:
            return { ...state, addError: '' }
        
        case CLEAR_TEAM :
            return {
                departments: [],
                isLoaded: false,
                isAdded: false,
                addError: ''
            }
        default: return state;

    }
}

export default TeamReducer