
import {
    GETSUBSCRIPTIONS,
    CLEARGETSUBSCRIPTIONS,
    GETSUBSCRIPTIONSLOADNIG,
    CLEARGETSUBSCRIPTIONSLOADNIG,
    SUBSCRIPTION_CANCELLED,
    SETFIRSTLOADED,
    SUBSCRIPTION_ADDED,
    CREATE_SUBSCRIPTION,
    CREATE_SUBSCRIPTION_RESPONSE,
    CREATE_SUBSCRIPTION_RESPONSE_CLEAR,
    CREATE_SUBSCRIPTION_ERROR,
    CREATE_SUBSCRIPTION_ERROR_CLEAR,
    EDIT_SUBSCRIPTION,
    EDIT_SUBSCRIPTION_ERROR,
    EDIT_SUBSCRIPTION_ERROR_CLEAR,
    EDIT_SUBSCRIPTION_RESPONSE,
    EDIT_SUBSCRIPTION_RESPONSE_CLEAR,
    SUBSCRIPTION_CANCELLED_CLEAR,
    CLEARSUBSCRIPTIONS
} from './SubscriptionsTypes'

const initialState = {
    subscriptions: [],
    subscriptionLoading: true,
    error: '',
    isFirstLoaded: false,
    isAdding: false,
    isAdded: false,
    addingError: '',
    isUpdated: false,
    isUpdating: false,
    updateError:'',
    isSubscriptionCancelled:false
}



const SubscriptionsReducer = (state = initialState, action) => {

    switch (action.type) {

        case CREATE_SUBSCRIPTION:
            return { ...state, isAdding: true }
            
        case EDIT_SUBSCRIPTION:
            return { ...state, isUpdating: true }

        case EDIT_SUBSCRIPTION_RESPONSE:
            let subscriptionsList = state.subscriptions.map((data, index) => {
                if (data._id === action.payload._id) {
                    return action.payload
                }
                else {
                    return data
                }
            })
            return { ...state, subscriptions: subscriptionsList, isUpdated: true, isUpdating: false }

        case EDIT_SUBSCRIPTION_RESPONSE_CLEAR:
            return { ...state, isUpdated: false }
        case EDIT_SUBSCRIPTION_ERROR:
            return { ...state,updateError:action.payload, isUpdating: false }
        case EDIT_SUBSCRIPTION_ERROR_CLEAR:
                 return { ...state, updateError: '' }
       

        case CREATE_SUBSCRIPTION_RESPONSE:
            let listSubscription = state.subscriptions
            return { ...state, isAdding: false, updateFilterSubscription: true, isAdded: true, subscriptions: [action.payload, ...listSubscription] }
        case CREATE_SUBSCRIPTION_RESPONSE_CLEAR:
            return { ...state, isAdded: false }
        case CREATE_SUBSCRIPTION_ERROR:
            return { ...state, addingError: action.payload, isAdding: false }
        case CREATE_SUBSCRIPTION_ERROR_CLEAR:
            return { ...state, addingError: '' }
        case SETFIRSTLOADED:

            return { ...state, isFirstLoaded: true }
        case GETSUBSCRIPTIONS:

            return { ...state, subscriptions: action.payload, subscriptionLoading: false, isFirstLoaded: true }
        case SUBSCRIPTION_CANCELLED:
            let subscription_list = state.subscriptions.filter(data => { return data._id != action.payload })
            return { ...state, subscriptions: subscription_list ,isSubscriptionCancelled:true }

            case SUBSCRIPTION_CANCELLED_CLEAR:
                return { ...state, isSubscriptionCancelled:false,isUpdating:false}

            case CLEARSUBSCRIPTIONS:
                return {
                    subscriptions: [],
                    subscriptionLoading: true,
                    error: '',
                    isFirstLoaded: false,
                    isAdding: false,
                    isAdded: false,
                    addingError: '',
                    isUpdated: false,
                    isUpdating: false,
                    updateError:'',
                    isSubscriptionCancelled:false
                }

                    

        default: return state;
    }



}

export default SubscriptionsReducer