export const LOGIN = 'LOGIN'
export const LOGIN_RESPONSE = 'LOGINRESPONSE'

export const LOGIN_ERROR = 'LOGIN_ERROR'
export const CLEAR_LOGIN_ERROR = 'CLEAR_LOGIN_ERROR'

export const GET_USER_DATA = 'GET_USER_DATA'
export const GET_USER_DATA_LOADED = 'GET_USER_DATA_LOADED'
export const GET_USER_DATA_ERROR = 'GET_USER_DATA_ERROR'

export const UPDATE_USER_DATA = 'UPDATE_USER_DATA'
export const UPDATE_USER_DATA_RESPOSE = 'UPDATE_USER_DATA_RESPOSE'
export const UPDATE_USER_DATA_ERROR = 'UPDATE_USER_DATA_ERROR'

export const UPDATE_USER_DATA_RESPOSE_CLEAR = 'UPDATE_USER_DATA_RESPOSE_CLEAR'

export const LOGOUT = 'LOGOUT'
