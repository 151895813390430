
export const GET_EMPLOYEE =  'GET_EMPLOYEE'
export const GET_EMPLOYEE_RESPONSE = 'GET_EMPLOYEE_RESPONSE'
export const GET_EMPLOYEE_ERROR = 'GET_EMPLOYEE_ERROR'
export const EMPLOYEE_UPDATE_MODAL_CLOSED = 'EMPLOYEE_UPDATE_MODAL_CLOSED'

export const EMPLOYEE_UPDATE = 'EMPLOYEE_UPDATE'
export const EMPLOYEE_UPDATE_RESPONSE = 'EMPLOYEE_UPDATE_RESPONSE'
export const EMPLOYEE_UPDATE_RESPONSE_CLEAR = 'EMPLOYEE_UPDATE_RESPONSE_CLEAR'
export const EMPLOYEE_UPDATE_ERROR = 'EMPLOYEE_UPDATE_ERROR'
export const EMPLOYEE_UPDATE_ERROR_CLEAR = 'EMPLOYEE_UPDATE_ERROR_CLEAR'

export const ADD_EMPLOYEE = 'ADD_EMPLOYEE'
export const ADD_EMPLOYEE_RESPONSE = 'ADD_EMPLOYEE_RESPONSE'
export const ADD_EMPLOYEE_RESPONSE_CLEAR = 'ADD_EMPLOYEE_RESPONSE_CLEAR'
export const ADD_EMPLOYEE_ERROR = 'ADD_EMPLOYEE_ERROR'
export const ADD_EMPLOYEE_ERROR_CLEAR = 'ADD_EMPLOYEE_ERROR_CLEAR'


export const ADD_GSUIT_EMPLOYEES = 'ADD_GSUIT_EMPLOYEES'
export const ADD_GSUIT_EMPLOYEES_RESPONSE = 'ADD_GSUIT_EMPLOYEES_RESPONSE'
export const ADD_GSUIT_EMPLOYEES_RESPONSE_CLEAR = 'ADD_GSUIT_EMPLOYEES_RESPONSE_CLEAR'
export const ADD_GSUIT_EMPLOYEES_ERROR = 'ADD_GSUIT_EMPLOYEES_ERROR'
export const ADD_GSUIT_EMPLOYEES_ERROR_CLEAR = 'ADD_GSUIT_EMPLOYEES_ERROR_CLEAR'

export const CLEAR_EMPLOYEE =  'CLEAR_EMPLOYEE'
