import React from 'react'
import { isEmail } from 'validator'
import { getUser, signIn, signUp, googleSignIn, verify, requestForgetPassword, resetPassword, verifyPilotUser } from '../ApiService'
import ProtectedRoute from './ProtectedRoute'

const AuthContext = React.createContext()

class AuthProvider extends React.Component {
    // token=localStorage.getItem('token');

    state = { isAuth: localStorage.getItem('token') !== null } // true }//

    componentDidMount() {


        this.checkToken()
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.children !== this.props.children) {
            this.checkToken()
        }
    }

    constructor(props) {
        super(props)
        this.googleSignIn = this.googleSignIn.bind(this)
        this.login = this.login.bind(this)
        this.signup = this.signup.bind(this)
        this.VerifyPilotUser = this.VerifyPilotUser.bind(this)
        this.requestForgetPassword = this.requestForgetPassword.bind(this)
    }

    async checkToken() {
        try {
            if (navigator.onLine) {
                var user
                try {
                    user = await getUser()
                } catch (e) {
                    this.setState({ isAuth: false })
                    this.logout()
                }

                try {
                    if (user) {
                        this.setState({ isAuth: true })
                    } else {
                        this.setState({ isAuth: false })
                    }
                }
                catch (e) {

                    this.setState({ isAuth: false })

                }

            }
        }
        catch (e) {

        }

    }



    async requestForgetPassword(email) {
        if (!isEmail(email)) {
            throw new Error('Valid email id is required!.')
        }
        let result
        try {
            result = await requestForgetPassword(email)
        } catch (e) {
            console.log(e.message)
            throw e
        }
        if (result.success) {
            return {
                success: true,
                user: result.user,
            }
        }

    }

    async VerifyPilotUser(email, code) {
        if (!isEmail(email)) {
            throw new Error('invalid path.')
        }
        if (!code) {
            throw new Error('invalid path.')
        }
        let result
        try {
            result = await verifyPilotUser(email, code)
        } catch (e) {
            console.log(e.message)
            throw e
        }

        if (result.success) {
            return ({ success: true, user: result.user })
        }

        this.setState({ isAuth: false })
        return false


    }

    async googleSignIn(data, LoginType) {
        if (!data) {
            throw new Error('Valid email id is required!.')
        }

        if (!LoginType) {
            throw new Error('LoginType Required..')
        }

        let result
        try {
            result = await googleSignIn(data, LoginType)
        } catch (e) {
            throw e
        }

        if (result.success) {
            this.setState({ isAuth: true })
            sessionStorage.setItem('userId', result.user._id)
            localStorage.setItem('userId', result.user._id)
            localStorage.setItem('token', result.token)
            return {
                success: true,
                token: result.token,
                user: result.user
            }
        }
        this.setState({ isAuth: false })

    }


    async login(email, password) {
        if (!isEmail(email)) {
            throw new Error('Valid email id is required!.')
        }

        if (!password) {
            throw new Error('Please enter a password.')
        }

        let result
        try {
            result = await signIn(email, password)
        } catch (e) {
            throw e
        }

        if (result.success) {
            if (!result.user.googleauth.isActive) {
                localStorage.setItem('token', result.token)
            }
            sessionStorage.setItem('userId', result.user._id)
            localStorage.setItem('userId', result.user._id)
            return {
                success: true,
                token: result.token,
                user: result.user,
            }
        }
        this.setState({ isAuth: false })

    }

    async signup(name, email, password) {
        if (!isEmail(email)) {
            throw new Error('Please enter a valid email')
        }

        if (password !== 'undefined') {
            if (
                password.length < 8
                // !password.match(
                //     /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/,
                // )
            ) {
                throw new Error('Please enter a password of min 8 characters.')
            }
        }

        let result
        try {
            result = await signUp(name, email, password)
        } catch (e) {
            console.log(e.message)
            throw e
        }

        if (result.success) {
            return ({ success: true, user: result.user })
        }
        this.setState({ isAuth: false })
        return false
    }

    // async verify ( code) {
    //     // if (!isEmail(email)) {
    //     //     throw new Error('No email id to verify.')
    //     // }

    //     if (code === 'undefined') {
    //         throw new Error('something went wrong, please retry....')
    //     }

    //     let result
    //     try {
    //         result = await verify(code)
    //     } catch (e) {
    //         console.log(e.message)
    //         throw e
    //     }

    //     if (result.success) {
    //         return result
    //     }
    //     return false
    // }

    async resetPassword(code, password) {

        if (code === 'undefined') {
            throw new Error('Please enter a verification code.')
        }
        if (password === 'undefined') {
            throw new Error('Please enter a NewPassword.')
        }

        let result
        try {
            result = await resetPassword(code, password)
        } catch (e) {
            console.log(e.message)
            throw e
        }

        if (result.success) {
            return result
        }
        return false
    }

    logout() {
        this.setState({ isAuth: false })
        localStorage.removeItem('token')
    }

    render() {
        return (
            <AuthContext.Provider
                value={{
                    isAuth: this.state.isAuth,
                    login: this.login,
                    signup: this.signup,
                    verify: this.verify,
                    logout: this.logout,
                    VerifyPilotUser: this.VerifyPilotUser,
                    requestForgetPassword: this.requestForgetPassword,
                    resetPassword: this.resetPassword,
                    googleSignIn: this.googleSignIn

                }}>
                {this.props.children}
            </AuthContext.Provider>
        )
    }
}

String.prototype.capitalize = function () {
    const text = this.toLowerCase()
    return text.replace(/(^|\s)([a-z])/g, function (m, p1, p2) {
        return p1 + p2.toUpperCase()
    })
}

const AuthConsumer = AuthContext.Consumer
export { AuthContext, AuthProvider, AuthConsumer }
