export const GET_TEAM_RESPONSE = 'GET_TEAM_RESPONSE'
export const GET_TEAM = 'GET_TEAM'

export const ADD_TEAM_RESPONSE = 'ADD_TEAM_RESPONSE'
export const ADD_TEAM_RESPONSE_CLEAR = 'ADD_TEAM_RESPONSE_CLEAR'


export const ADD_TEAM_ERROR = 'ADD_TEAM_ERROR'
export const ADD_TEAM_ERROR_CLEAR = 'ADD_TEAM_ERROR_CLEAR'

export const CLEAR_TEAM = 'CLEAR_TEAM'
