import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AuthConsumer } from './AuthContext'
import { useDispatch, useSelector } from 'react-redux'

const ProtectedRoute = ({component: Component, ...rest}) => (
 
  <AuthConsumer>
    {({isAuth}) => (
      
      <Route
        render={props => {
          return true ? <Component {...props} /> : <Redirect to="/login" />
        }}
        {...rest}
      />
    )}
  </AuthConsumer>
)
export default ProtectedRoute
