import { REMOVE_NOTIFICATION, SET_NOTIFICATION } from './NotificationTypes'

const initialState = {
    isNotify: false,
    text: '',
    variant: ''
}


const NotificationReducer = (state = initialState, action) => {

    switch (action.type) {
        case REMOVE_NOTIFICATION:

            return { ...state, isNotify: false, text: '', variant: '' }

        case SET_NOTIFICATION:

            return { ...state, isNotify: true, variant: action.payload.variant, text: action.payload.text }

        default: return state;

    }
}

export default NotificationReducer