
import {

    GET_CANCELLED_SUBSCRIPTION,
    GET_CANCELLED_SUBSCRIPTION_RESPONSE,
    ADD_CANCELLED_SUBSCRIPTION_DATA,
    REMOVE_CANCELLED_SUBSCRIPTION_DATA,
    REMOVE_CANCELLED_SUBSCRIPTION_DATA_RESPONSE_CLEAR,
    CLEAR_CANCELLED_SUBSCRIPTION

} from './CSubscritionTypes'

const initialState = {
    subscriptions: [],
    isLoading: false,
    isLoaded: false,
    isEmployeeUpdated: false,
    employeeUpdateError: '',
    departmentCloseModal: false,
    isDeleted: false
}

const CSubscriptionReducer = (state = initialState, action) => {

    switch (action.type) {

        case GET_CANCELLED_SUBSCRIPTION:
            return { ...state, isLoading: true, isLoaded: true }
        case REMOVE_CANCELLED_SUBSCRIPTION_DATA:
            let newSubscriptiosList = state.subscriptions.filter(data => { if (data._id !== action.payload) { return data } })
            return { ...state, isDeleted: true, subscriptions: newSubscriptiosList }

        case REMOVE_CANCELLED_SUBSCRIPTION_DATA_RESPONSE_CLEAR:
            return { ...state, isDeleted: false }
        case GET_CANCELLED_SUBSCRIPTION_RESPONSE:
            return { ...state, isLoading: false, subscriptions: action.payload }

        case ADD_CANCELLED_SUBSCRIPTION_DATA:
            return { ...state, subscriptions: [action.payload, ...state.subscriptions] }
        case CLEAR_CANCELLED_SUBSCRIPTION :
            return {
                subscriptions: [],
                isLoading: false,
                isLoaded: false,
                isEmployeeUpdated: false,
                employeeUpdateError: '',
                departmentCloseModal: false,
                isDeleted: false
            }

        default: return state;

    }

}

export default CSubscriptionReducer