

import {
    GET_IMPORT_SUPSCRIPTION_REMINING_COUNT,
    GET_IMPORT_SUPSCRIPTION_REMINING_COUNT_RESPONSE,
    ADD_IMPORT_SUBSCRIPTIONS,
    ADD_IMPORT_SUBSCRIPTIONS_RESPONSE,
    ADD_IMPORT_SUBSCRIPTIONS_RESPONSE_CLEAR,
    ADD_IMPORT_SUBSCRIPTIONS_ERROR,
    ADD_IMPORT_SUBSCRIPTIONS_ERROR_CLEAR,
    CLEAR_IMPORT_SUPSCRIPTION_REMINING_COUNT
} from './ImportsubscriptionTypes'

const initialState = {
    reminingCount: 0,
    countLoading: true,
    countLoaded: false,
    isImportSubscriptionAdded:false,
    isImportSubscriptionAdding:false
}



const ImportsubscriptionReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_IMPORT_SUPSCRIPTION_REMINING_COUNT:

            return { ...state, countLoading: true, countLoaded: true }
        case GET_IMPORT_SUPSCRIPTION_REMINING_COUNT_RESPONSE:

            return { ...state, countLoading: false, reminingCount: action.payload }

        case ADD_IMPORT_SUBSCRIPTIONS:

            return { ...state, isImportSubscriptionAdding: true }

        case ADD_IMPORT_SUBSCRIPTIONS_RESPONSE:

            return { ...state, isImportSubscriptionAdding: false,isImportSubscriptionAdded:true }

        case ADD_IMPORT_SUBSCRIPTIONS_RESPONSE_CLEAR:

            return { ...state, isImportSubscriptionAdded: false }
        case ADD_IMPORT_SUBSCRIPTIONS_ERROR:

            return { ...state, isImportSubscriptionAdding: false }
        case ADD_IMPORT_SUBSCRIPTIONS_ERROR_CLEAR:

            return { ...state, isImportSubscriptionAdding: false }

        case CLEAR_IMPORT_SUPSCRIPTION_REMINING_COUNT :
            return {
                reminingCount: 0,
                countLoading: true,
                countLoaded: false,
                isImportSubscriptionAdded:false,
                isImportSubscriptionAdding:false
            }

        default: return state;

    }
}

export default ImportsubscriptionReducer
