import { combineReducers } from 'redux'

import SubscriptionsReducer from './Subscriptions/SubscriptionsReducer'

import UserReducer from './User/UserReducer'

import TagReducer from './Tag/TagReducer'

import EmployeeReducer from './Employee/EmployeeReducer';

import TeamReducer from './Team/TeamReducer'

import CSubscriptions from './CSubscription/CSubscriptionReducer'

import chartReducer from './Chart/chartReducer'

import auditReducer from './Audit/AuditReducer'

import SummaryReducer from './Summary/SummaryReducer'

import UsageMatrixReducer from './UsageMatrix/UsageMatrixReducer'

import OrganizationReducer from './Organization/OrganizationReducer'
import NotificationReducer from './Notification/NotificationReducer';
import QuicksubscriptionReducer from './Quicksubscription/QuicksubscriptionReducer';
import ImportsubscriptionReducer from './Importsubscription/ImportsubscriptionReducer';


const rootReducer = combineReducers ({
    subscriptions:SubscriptionsReducer,
    user:UserReducer,
    tag:TagReducer,
    employee:EmployeeReducer,
    team:TeamReducer,
    cancelsubscriptions:CSubscriptions,
    chart:chartReducer ,
    audit: auditReducer,
    summary:SummaryReducer,
    usageMatrix:UsageMatrixReducer,
    organization:OrganizationReducer,
    notification:NotificationReducer,
    quicksubscriptions:QuicksubscriptionReducer,
    importsubscriptions:ImportsubscriptionReducer
})

export default rootReducer
