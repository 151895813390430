import {
    GET_ORGANIZATION,
    GET_ORGANIZATION_ERROR,
    GET_ORGANIZATION_RESPONSE,
    CLEAR_ORGANIZATION
    } from './OrganizationTypes'


    const initialState = {
        organization: {},
        isLoading: false,
        isLoaded: false,
        
    }

    const OrganizationReducer = (state = initialState, action) => {
        switch (action.type) {
            case GET_ORGANIZATION:
            return { ...state, isLoading: true, isLoaded: true }
            case GET_ORGANIZATION_RESPONSE:
            return { ...state, isLoading: false,organization:action.payload}
            case GET_ORGANIZATION_ERROR:
            return { ...state, isLoading: false}

            case CLEAR_ORGANIZATION :
                return {
                    organization: {},
                    isLoading: false,
                    isLoaded: false,
                    
                }

        default: return state;
        }
    }

    export default OrganizationReducer