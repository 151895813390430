import {GET_SUMMARY_DATA,GET_SUMMARY_DATA_RESPONSE,CLEAR_SUMMARY_DATA} from './SummaryTypes'

const initialState = {
    isLoading: false,
    isLoaded: false,
    allSubscriptions:'',
    cost:'',
    unusedSubscriptions:''
}

const SummaryReducer = (state = initialState, action) => {

    switch (action.type) {

    case GET_SUMMARY_DATA:
        return { ...state, isLoading: true ,isLoaded:true}
        case GET_SUMMARY_DATA_RESPONSE:
            return { ...state, isLoading: false,allSubscriptions:action.payload.allSubscriptions,
                cost:action.payload.spendLastMonth.cost,unusedSubscriptions:action.payload.unusedSubscriptions
                
            }
        case CLEAR_SUMMARY_DATA :
            return {
                isLoading: false,
                isLoaded: false,
                allSubscriptions:'',
                cost:'',
                unusedSubscriptions:''
            }
    default: return state;
    }
}

export default SummaryReducer


