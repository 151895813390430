import {GET_USAGE_MATRIX_DATA,GET_USAGE_MATRIX_DATA_RESPONSE,CLEAR_USAGE_MATRIX_DATA} from './UsageMatrixTypes'

const initialState = {
    isLoading: false,
    isLoaded: false,
    result:{subscriptions: [], employees: []}
    
}

const UsageMatrixReducer = (state = initialState, action) => {

    switch (action.type) {

    case GET_USAGE_MATRIX_DATA:
        return { ...state, isLoading: true ,isLoaded:true}
        case GET_USAGE_MATRIX_DATA_RESPONSE:
            return { ...state, isLoading: false,result:action.payload
                
            }
        case  CLEAR_USAGE_MATRIX_DATA:
            return {
                isLoading: false,
                isLoaded: false,
                result:{subscriptions: [], employees: []}
                
            }
    default: return state;
    }
}

export default UsageMatrixReducer


